.aws-btn {

    --slider-height-percentage: 33%;
    --slider-transition-duration: 700ms;
    --organic-arrow-thickness: 2px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 20px;
    --organic-arrow-color: #5e5e5e;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #2d5182;
    --control-bullet-active-color: #26456f;
    --loader-bar-color: #ffffff;
    --loader-bar-height: 1px;
    }